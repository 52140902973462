import * as React from "react";
import Skeletons from "@mui/material/Skeleton";

export default function Skeleton() {
  return (
    <Skeletons
      variant="rectangular"
        width={320}
        height={318}
    />
  );
}

// GlobalStateContext.js
import React, { createContext, useState } from "react";

const GlobalStateContext = createContext();

export const GlobalStateProvider = ({ children }) => {
  const [siteViewSummary, setSiteViewSummary] = useState({});
  const [buyNowSummary, setBuyNowSummary] = useState({});
  const [amazonSummary, setAmazonSummary] = useState({});

  return (
    <GlobalStateContext.Provider
      value={{
        siteViewSummary,
        setSiteViewSummary,
        buyNowSummary,
        setBuyNowSummary,
        amazonSummary,
        setAmazonSummary,
      }}
    >
      {children}
    </GlobalStateContext.Provider>
  );
};

export default GlobalStateContext;

import React from "react";
import cuclogo from "../../assets/callup-calendar-logo-04.webp";

const AdminHeader = () => {
  return (
    <div style={{display:'flex', alignItems:'center', marginBottom:'20px'}}>
      <img
        loading="lazy"
        src={cuclogo}
        alt="callup calendar logo: Top MLB Prospects"
        width={'100px'}
        height={'auto'}
      />
      <h2>Callup Calendar: Dashboard</h2>
    </div>
  );
};

export default AdminHeader;

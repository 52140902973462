import React, { useContext, useEffect, useState } from "react";
import { Box, Grid, Paper, styled } from "@mui/material";
import BarCharts from "../../components/BarCharts";
import { apiCall } from "../../utils/httpClient";
import { GLOBAL_URL } from "../../utils/Constant";
import Skeleton from "../../components/Skeleton";
import TableData from "../../components/TableData";
import moment from "moment";
import { getFromAndToDate } from "../../utils/helperFunction";
import GlobalStateContext from "../../context/DashboardDataContext";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const SiteViewChart = ({ noOfDays, setSummaryData, summaryData }) => {
  const { siteViewSummary, setSiteViewSummary } = useContext(GlobalStateContext);
  const [chartData, setChartData] = useState({});
  const [siteViewData, setSiteViewData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [secondTabData, setSecondTabData] = useState([]);

  useEffect(() => {
    getSiteViewData();
  }, [noOfDays]);

  useEffect(() => {
    if (siteViewData.length > 0) {
      const x_label = siteViewData
        ?.map((obj) => obj?.dateRange)
        ?.filter((obj) => obj !== null)
        ?.reverse();

      const y_label = siteViewData
        ?.filter((obj) => obj?.dateRange !== null)
        ?.map((obj) => obj?.count)
        ?.reverse();
      setChartData({ ...chartData, x_label: x_label, y_label: y_label });
    }
  }, [siteViewData]);

  const getSiteViewData = async () => {
    setIsLoading(true);
    const response = await apiCall(
      "POST",
      `${GLOBAL_URL}/api/dashboard/getSiteViewEventData`,
      {
        daysToAdd: noOfDays,
      }
    );
    if (response && response?.data) {
      setIsLoading(false);
      setSiteViewData(response.data.summary);
      setSiteViewSummary(response.data.summary)
      const createTable = response.data.detailData
        .map((data) => {
          return {
            eventName: "Site View",
            data_time: data.eventDate,
            country: data?.browseLocation?.country
              ? data?.browseLocation?.country
              : "",
            region: data?.browseLocation?.region
              ? data?.browseLocation?.region
              : "",
            city: data?.browseLocation?.city ? data?.browseLocation?.city : "",
          };
        })
        .sort((a, b) => {
          const dateA = new Date(a.data_time);
          const dateB = new Date(b.data_time);
          return dateB - dateA;
        });
      setSecondTabData(createTable);
    }
  };

  return (
    <div>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems={"center"}
        flexWrap={"wrap"}
      >
        <Box flex={1} p={1}>
          <Box
            sx={{
              fontSize: "25px",
              fontWeight: "400",
              textDecoration: "underline",
              marginBottom: "45px",
            }}
          >
            Site View Chart:{" "}
            {chartData?.x_label &&
              `${getFromAndToDate(chartData, noOfDays).from} to ${
                getFromAndToDate(chartData, noOfDays).to
              }`}
          </Box>
          {!isLoading ? <BarCharts data={chartData} /> : <Skeleton />}
        </Box>
        <Box flex={1} p={1}>
          <TableData
            firstTabData={siteViewData}
            firstTablLabel="Site View Data"
            secondTabData={secondTabData}
            secondTablLabel="Details"
            name="Site View Data"
            noOfDays={noOfDays}
            isLoading={isLoading}
          />
        </Box>
      </Box>
    </div>
  );
};

export default SiteViewChart;

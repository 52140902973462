import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  TableSortLabel,
  Box,
  Skeleton,
} from "@mui/material";
import DownloadButton from "./DownloadButton";

const TableData = ({
  title,
  firstTabData,
  firstTablLabel,
  name,
  noOfDays,
  isLoading,
  secondTabData,
  secondTablLabel
}) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const totalEventCount =
    firstTabData?.length > 0 &&
    firstTabData?.reduce(
      (accumulator, currentValue) => accumulator + currentValue.count,
      0
    );

  const tableType =
    name === "Team Data" ? 1 : name === "Clicked Books Data" ? 2 : 3;
  const scrollType = name === "Team Data" ? "scroll" : "hidden";

  return (
    <Paper
      sx={{
        maxWidth: "100%",
        width: "100%",
        "@media (min-width:600px)": {
          maxWidth: "500px",
          minWidth: "390px",
        },
        mx: "auto",
        overflowX: scrollType,
        ml: "0",
      }}
    >
      <Box
        sx={{
          fontSize: "17px",
          padding: "15px",
          fontWeight: "500",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box sx={{ textDecoration: "underline" }}>{name}</Box>
        <Box
          sx={{ textAlign: "center" }}
          display={"flex"}
          alignItems={"center"}
          gap={1}
        >
          <Box>
            <Box sx={{ fontSize: "12px" }}>
              Total count in last {noOfDays * 10} days
            </Box>

            <Box sx={{ fontSize: "15px", textAlign: "center" }}>
              {" "}
              {!isLoading ? (
                totalEventCount
              ) : (
                <Skeleton width={"40px"} sx={{ margin: "auto" }} />
              )}
            </Box>
          </Box>
          <DownloadButton
            firstTabData={firstTabData}
            firstTablLabel={firstTablLabel}
            buttonFor={name}
            secondTabData={secondTabData}
            secondTabLabel={secondTablLabel}
            noOfDays={noOfDays}
          />
        </Box>
      </Box>
      <TableContainer
        sx={{
          overflow: "auto",
          width: scrollType === "auto" ? "auto" : "100%",
          padding: "0 15px",
          maxHeight: "330px",
          minHeight: "330px",
        }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: "600" }}>
                {tableType === 1
                  ? "Match Info"
                  : tableType === 2
                  ? "Amazon Book Name"
                  : "Date"}
              </TableCell>
              <TableCell sx={{ fontWeight: "600" }}>Count</TableCell>
            </TableRow>
          </TableHead>
          {!isLoading ? (
            <TableBody>
              {firstTabData
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((item) => (
                  <TableRow key={item.dateRange}>
                    <TableCell>
                      {tableType === 1
                        ? `${item.teamVs} (${item.when})`
                        : tableType === 2
                        ? item.bookName
                        : item.dateRange}
                    </TableCell>
                    <TableCell>{item.count}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          ) : (
            ["", "", "", "", ""].map((el) => {
              return (
                <TableRow>
                  <TableCell>
                    <Skeleton
                      variant="rounded"
                      width={"100%"}
                      height={"20px"}
                    />
                  </TableCell>
                  <TableCell>
                    <Skeleton
                      variant="rounded"
                      width={"100%"}
                      height={"20px"}
                    />
                  </TableCell>
                </TableRow>
              );
            })
          )}
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={firstTabData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
};

export default TableData;

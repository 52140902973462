export default {
  GET_CALENDER_LIST: "players/getPlayersList",
  GET_SCHEDULE_LIST: "schedule/getScheduleList",
  ADD_USER_EVENT: "user/addUserEvent",
  USERDETAILS:"user/userDetails",
  REGISTERUSER:'user/registerUser',
  // Admin Dashboard
  GET_STADIUM_LIST: "schedule/getStadium",
  GET_LAT_LNG: "schedule/getlatlong",
  GET_DASHBOARD_DATA: "user/getDashBoardData",
};

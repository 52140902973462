import React, { useState } from "react";
import { Box } from "@mui/material";
import SiteViewChart from "./SiteViewChart";
import BuyNowTicketChart from "./BuyNowTicketChart";
import AmazonEventChart from "./AmazonEventChart";
import BasicSelect from "../../components/BasicSelect";
import AdminHeader from "./AdminHeader";
// import "../../assets/styles/styles.css";

const AdminDashboard = () => {
  const [noOfDays, setNoOfDays] = useState(15);
  const [summaryData, setSummaryData] = useState({})
  return (
    <Box className="container hero-container conatiner-xl mt-3 mb-3">
     <AdminHeader />
     <div className="border-gray mb-3"></div>
      <BasicSelect value={noOfDays} setValue={setNoOfDays} />
      <SiteViewChart noOfDays={noOfDays} setSummaryData={setSummaryData} summaryData={summaryData}/>
      <div className="border-gray"></div>
      <BuyNowTicketChart noOfDays={noOfDays} setSummaryData={setSummaryData} summaryData={summaryData}/>
      <div className="border-gray"></div>
      <AmazonEventChart noOfDays={noOfDays} setSummaryData={setSummaryData} summaryData={summaryData}/>
    </Box>
  );
};

export default AdminDashboard;

import React, { createContext, useState, useEffect } from "react";

const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {
  const [authData, setAuthData] = useState(() => {
    // Retrieve and decode stored data from localStorage when initializing the state
    const savedAuthData = localStorage.getItem('authData');
    return savedAuthData ? JSON.parse(atob(savedAuthData)) : null;
  });

  useEffect(() => {
    // Encode and save the authData to localStorage whenever it changes
    if (authData) {
      localStorage.setItem('authData', btoa(JSON.stringify(authData)));
    } else {
      // Clear authData from localStorage if it's null
      localStorage.removeItem('authData');
    }
  }, [authData]);

  return (
    <AuthContext.Provider value={{ authData, setAuthData }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;

import  { useEffect } from 'react';
import ReactGA from 'react-ga';

const ScrollTracker = () => {
  useEffect(() => {
    const handleScroll = () => {
      // Replace 200 with the percentage of the page scrolled you want to track
      // if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight * 0.8) {
        ReactGA.event({
          category: 'Scroll Depth',
          action: 'Scrolled 80%',
          label: 'Page Scrolled 80%',
        });
      // }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return null; // Since this is a utility component, it doesn't render anything
};

export default ScrollTracker;

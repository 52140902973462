import { GiFlame } from "react-icons/gi";
import { apiCall } from "./httpClient";
import apiEndPoints from "./apiEndPoints";
import { Link } from "react-scroll";
import { TiArrowRightThick } from "react-icons/ti";
import { IoMdArrowRoundUp } from "react-icons/io";
import { FaUserInjured } from "react-icons/fa";
import { isProdction } from "./Constant";
import moment from "moment";

export const getRowData = (data, getCellColor, handleCellClick) => {
  return (
    <p>
      {data &&
        data != "undefined" &&
        data?.length > 0 &&
        data
          ?.filter((row) => row?.team)
          ?.map((team, i) => {
            const getCellClr = getCellColor(team);

            return team != "undefined" ? (
              <div
                key={i}
                className={`d-flex modal-n7d-row ${
                  getCellClr === "red"
                    ? "red-cell"
                    : getCellClr === "orange"
                    ? "orange-cell"
                    : getCellClr === "green"
                    ? "green-cell"
                    : ""
                }`}
                style={{
                  flexWrap: "wrap",
                  cursor: "pointer",
                  position: "relative",
                }}
                onClick={() =>
                  handleCellClick(team.team, team.date, "next 7 Day")
                }
              >
                {getCellClr === "red" && (
                  <GiFlame
                    style={{
                      fontSize: "20px",
                      marginRight: "20px",
                      marginBottom: "7px",
                    }}
                  />
                )}
                <div style={{ width: "12vw" }}>
                  {team?.home_or_away === "H" ? team?.vs : team?.team}
                </div>
                <div>@</div>
                <div style={{ width: "12vw", marginLeft: "8px" }}>
                  {team?.home_or_away === "H" ? team?.team : team?.vs}
                </div>
                <div style={{ width: "22vw" }}>
                  {moment(team?.date).format("MM/DD (ddd)")}
                </div>
                {/* <div style={{ width: "24vw" }}>
                    {" "} */}
                {/* {convertTimeInETZone(team.date, team.time)} */}
                {/* </div> */}
                {/* <div>
                    {team.venue?.length > 15
                      ? `${team.venue.slice(0, 13)}...`
                      : team.venue}
                  </div> */}
                <div
                  style={{
                    fontWeight: "400",
                    fontSize: "11px",
                    marginTop: "4px",
                    textAlign: "end",
                    position: "absolute",
                    right: "15px",
                    textDecoration: "underline",
                  }}
                >
                  Details
                </div>
              </div>
            ) : (
              ""
            );
          })}
    </p>
  );
};

export const handleAddEvent = async (params) => {
  if (isProdction) {
    const response = await apiCall("POST", apiEndPoints.ADD_USER_EVENT, params);
    return response;
  }
};


export const makeTableRows = (
  today,
  tomorrow,
  nextDay,
  addDays,
  tempArr,
  getCellColor,
  getGameInfo,
  getGameInfoData,
  getNext7DaysGameInfo,
  getNext7DaysGameInfoData,
  handleCellClick,
  setNext7DayModalData,
  handleOpenModal,
  setMilb_in_modal
) => {
  const makeTableRows = [...tempArr].map((obj, i) => {
    // Regular expression pattern to extract the number
    const pattern = /\d+/;
    // Using match() to find all matches of the pattern in the string
    const numbers = obj?.prospectStatus?.match(pattern);
    // If you expect only one number, you can directly access it like this
    var prospect_status = "";
    if (numbers && numbers.length > 0) {
      const extractedNumber = parseInt(numbers[0], 10);
      prospect_status = { status: "Injured", days: extractedNumber };
    } else {
      prospect_status = { status: "Active" };
    }

    const getCellColorToday = getCellColor(
      getGameInfoData(obj.MILB_Team, today),
      i
    );
    const getCellColorTomorrow = getCellColor(
      getGameInfoData(obj.MILB_Team, tomorrow),
      i
    );
    const getCellColorNextDay = getCellColor(
      getGameInfoData(obj.MILB_Team, nextDay),
      i
    );

    const TempNext7DayGameInfo = getNext7DaysGameInfo(
      obj.MILB_Team,
      today,
      addDays
    );
    const next7DayGameInfo =
      TempNext7DayGameInfo?.length > 0
        ? TempNext7DayGameInfo.filter((tm) => tm?.date)
        : [];
    const getCellColorNext7Day =
      next7DayGameInfo?.length > 0
        ? getCellColor(next7DayGameInfo[0], i)
        : "lightgray";
    const dataObj = {
      rank: Math.round(obj.actualRank),
      player: obj.prospectName,
      pos: obj.Position,
      mlb: obj.MLB_Team,
      milb: obj.MILB_Team,
      eta: obj.eta ? obj.eta : "",
      today:
        obj?.prospectStatus === "Active"
          ? getGameInfoData(obj.MILB_Team, today, "today")
          : null,
      tomorrow:
        obj?.prospectStatus === "Active"
          ? getGameInfoData(obj.MILB_Team, tomorrow, "tomorrow")
          : null,
      next_day:
        obj?.prospectStatus === "Active"
          ? getGameInfoData(obj.MILB_Team, nextDay, "nextDay")
          : null,
      next_7_day:
        obj?.prospectStatus === "Active"
          ? getNext7DaysGameInfoData(obj.MILB_Team, today, addDays)
          : [],
      prospect_status: prospect_status,
    };

    // const prospectActiveStatus = obj.prospectStatus.includes('Injured') ? obj.prospectStatus?.split()

    return {
      rank: (
        <div className={`each-cell border-radius-left`}>
          {obj.actualRank ? Math.round(obj.actualRank) : ""}
        </div>
      ),
      player: (
        <div
          className={`each-cell ${
            prospect_status?.status === "Active"
              ? obj.MILB_Team
                ? ""
                : "text-line-through"
              : "text-line-through"
          }`}
        >
          {obj.prospectName}{" "}
          {prospect_status?.status !== "Active" ? (
            <span
              style={{
                marginLeft: "10px",
                marginBottom: "5px",
                fontSize: "18px",
              }}
            >
              <FaUserInjured />
            </span>
          ) : obj.MILB_Team ? (
            ""
          ) : (
            <span
              style={{
                marginLeft: "10px",
                marginBottom: "5px",
                fontSize: "18px",
              }}
            >
              <IoMdArrowRoundUp />
            </span>
          )}
        </div>
      ),
      pos: <div className={`each-cell`}>{obj.Position}</div>,
      mlb: (
        <div className={`each-cell ${obj.MILB_Team ? "" : "text-bold"}`}>
          {obj.MLB_Team}
        </div>
      ),
      milb: <div className={`each-cell`}>{obj.MILB_Team}</div>,
      eta: <div className={`each-cell`}>{obj.eta ? obj.eta : ""}</div>,
      today:
        prospect_status?.status === "Active" ? (
          <Link
            // to={showPagination ? "sidebar-info" : "abc"}
            to={"sidebar-info"}
            spy={true}
            smooth={true}
            offset={220} // Adjust the offset as needed
            duration={500}
          >
            <div
              className={`each-cell ${
                getCellColorToday === "red"
                  ? "red-cell"
                  : getCellColorToday === "orange"
                  ? "orange-cell"
                  : getCellColorToday === "green"
                  ? "green-cell"
                  : ""
              }`}
              style={{ textDecoration: "underline" }}
              onClick={() => handleCellClick(obj.MILB_Team, today, "today")}
            >
              {getCellColorToday === "red" && (
                <GiFlame
                  style={{
                    fontSize: "15px",
                    marginRight: "5px",
                    marginBottom: "2px",
                  }}
                />
              )}
              {getGameInfo(obj.MILB_Team, today, "today")}
            </div>
          </Link>
        ) : (
          <div className={`each-cell`}></div>
        ),
      tomorrow:
        prospect_status?.status === "Active" ? (
          <Link
            to={"sidebar-info"}
            spy={true}
            smooth={true}
            offset={75} // Adjust the offset as needed
            duration={500}
          >
            <div
              className={`each-cell ${
                getCellColorTomorrow === "red"
                  ? "red-cell"
                  : getCellColorTomorrow === "orange"
                  ? "orange-cell"
                  : getCellColorTomorrow === "green"
                  ? "green-cell"
                  : ""
              }`}
              style={{ textDecoration: "underline" }}
              onClick={() =>
                handleCellClick(obj.MILB_Team, tomorrow, "tomorrow")
              }
            >
              {getCellColorTomorrow === "red" && (
                <GiFlame
                  style={{
                    fontSize: "15px",
                    marginRight: "5px",
                    marginBottom: "2px",
                  }}
                />
              )}
              {getGameInfo(obj.MILB_Team, tomorrow, "tomorrow")}
            </div>
          </Link>
        ) : (
          <div className={`each-cell`}></div>
        ),
      next_day:
        prospect_status?.status === "Active" ? (
          <Link
            to={"sidebar-info"}
            spy={true}
            smooth={true}
            offset={75} // Adjust the offset as needed
            duration={500}
          >
            <div
              className={`each-cell ${
                getCellColorNextDay === "red"
                  ? "red-cell"
                  : getCellColorNextDay === "orange"
                  ? "orange-cell"
                  : getCellColorNextDay === "green"
                  ? "green-cell"
                  : ""
              }`}
              style={{ textDecoration: "underline" }}
              onClick={() => handleCellClick(obj.MILB_Team, nextDay, "nextDay")}
            >
              {getCellColorNextDay === "red" && (
                <GiFlame
                  style={{
                    fontSize: "15px",
                    marginRight: "5px",
                    marginBottom: "2px",
                  }}
                />
              )}
              {getGameInfo(obj.MILB_Team, nextDay, "nextDay")}
            </div>
          </Link>
        ) : (
          <div className={`each-cell`}></div>
        ),
      next_7_day:
        prospect_status?.status === "Active" ? (
          <div
            className={`each-cell border-radius-right ${
              getCellColorNext7Day === "red"
                ? "red-cell"
                : getCellColorNext7Day === "orange"
                ? "orange-cell"
                : getCellColorNext7Day === "green"
                ? "green-cell"
                : ""
            }`}
            data-toggle="modal"
            data-target="#exampleModalLong"
            onClick={() => {
              setNext7DayModalData(next7DayGameInfo);
              next7DayGameInfo?.length > 0 && handleOpenModal("Next 7 Days");
              setMilb_in_modal(dataObj);
            }}
          >
            <div>
              {next7DayGameInfo?.length > 0 ? (
                <div className="d-flex aic">
                  {/* <div style={{ display: "none" }}>{next7DayGameInfo}</div> */}
                  <div style={{ textDecoration: "underline" }}>
                    {next7DayGameInfo?.length > 0 &&
                    next7DayGameInfo[0]?.home_or_away === "H" ? (
                      <div>
                        {getCellColorNext7Day === "red" && (
                          <GiFlame
                            style={{
                              fontSize: "15px",
                              marginRight: "5px",
                              marginBottom: "2px",
                            }}
                          />
                        )}
                        {`${next7DayGameInfo[0].team} 
                     
                      `}
                      </div>
                    ) : (
                      `@${next7DayGameInfo[0].vs} `
                    )}
                  </div>{" "}
                  &nbsp;
                  <div
                    style={{
                      fontWeight: "400",
                      fontSize: "11px",
                      // marginTop: "4px",
                      textAlign: "end",
                      position: "absolute",
                      right: "10px",
                      textDecoration: "underline",
                    }}
                  >
                    <TiArrowRightThick
                      style={{
                        fontSize: "15px",
                      }}
                    />
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        ) : (
          <div className={`each-cell`}></div>
        ),
      data: dataObj,
    };
  });
  return makeTableRows;
};

export const getFromAndToDate = (chartData, noOfDays) => {
  if (noOfDays > 1) {
    const fromDate =
      chartData?.x_label && chartData?.x_label[0]?.split(" to ")[0];
    const toDate =
      chartData?.x_label &&
      chartData?.x_label[chartData?.x_label?.length - 1]?.split(" to ")[1];
    return { from: fromDate, to: toDate };
  } else {
    const fromDate = chartData?.x_label && chartData?.x_label[0];
    const toDate =
      chartData?.x_label && chartData?.x_label[chartData?.x_label?.length - 1];
    return { from: fromDate, to: toDate };
  }
};

import React from 'react'
import loaderImg from "../assets/loader.gif";
import logoImg from "../assets/callup-calendar-logo-04.webp";

const LoadingScreenFullPage = () => {
  return (
    <div
            style={{
              width: "100%",
              height: "100vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <img src={logoImg} width={350} alt="call up calendar logo" />
            <img src={loaderImg} width={50} alt="call up calendar loading" />
          </div>
  )
}

export default LoadingScreenFullPage
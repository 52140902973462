import React, { useContext, useEffect, useState } from "react";
import { Box } from "@mui/material";
import BarCharts from "../../components/BarCharts";
import { apiCall } from "../../utils/httpClient";
import { GLOBAL_URL } from "../../utils/Constant";
import Skeleton from "../../components/Skeleton";
import TableData from "../../components/TableData";
import { getFromAndToDate } from "../../utils/helperFunction";
import GlobalStateContext from "../../context/DashboardDataContext";

const AmazonEventChart = ({ noOfDays }) => {
  const { amazonSummary, setAmazonSummary } = useContext(GlobalStateContext);
  const [chartData, setChartData] = useState({});
  const [amazonEventData, setAmazonEventData] = useState([]);
  const [amazonBooksData, setAmazonBooksData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [secondTabData, setSecondTabData] = useState([]);

  useEffect(() => {
    getAmazonEventData();
  }, [noOfDays]);

  useEffect(() => {
    if (amazonEventData.length > 0) {
      const x_label = amazonEventData
        ?.map((obj) => obj?.dateRange)
        ?.filter((obj) => obj !== null)
        ?.reverse();

      const y_label = amazonEventData
        ?.filter((obj) => obj?.dateRange !== null)
        ?.map((obj) => obj?.count)
        ?.reverse();
      setChartData({ ...chartData, x_label: x_label, y_label: y_label });
    }
  }, [amazonEventData]);

  const getAmazonEventData = async () => {
    setIsLoading(true);
    const response = await apiCall(
      "POST",
      `${GLOBAL_URL}/api/dashboard/getAmazonEventData`,
      {
        daysToAdd: noOfDays,
      }
    );
    if (response?.data) {
      setIsLoading(false);
      const newData = response?.data?.amazonData.map(obj => {
        const newObj = { ...obj }; // Create a copy of the object
        delete newObj['details']; // Delete details array because creating blank column in download csv not required yet 
        return newObj;
      });
      setAmazonSummary(newData)
      setAmazonEventData(newData);
      const newData2 = response?.data?.bookData.map(obj => {
        const newObj = { ...obj }; // Create a copy of the object
        delete newObj['details']; // Delete details array because creating blank column in download csv not required yet 
        return newObj;
      });
      setAmazonBooksData(newData2);
      const createTable = response?.data?.detailData
        ?.map((data) => {
          return {
            eventName: "Buy Now Ticket",
            data_time: data?.eventDate,
            book_name: data?.eventData?.bookDetails?.name,
            book_link: data?.eventData?.bookDetails?.link,
            country: data?.browseLocation?.country
              ? data?.browseLocation?.country
              : "",
            region: data?.browseLocation?.region
              ? data?.browseLocation?.region
              : "",
            city: data?.browseLocation?.city ? data?.browseLocation?.city : "",
          };
        })
        .sort((a, b) => {
          const dateA = new Date(a.data_time);
          const dateB = new Date(b.data_time);
          return dateB - dateA;
        });
      setSecondTabData(createTable);
    }
  };

  return (
    <div>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems={"center"}
        flexWrap={"wrap"}
      >
        <Box flex={1} p={1}>
          <Box
            sx={{
              fontSize: "25px",
              fontWeight: "400",
              textDecoration: "underline",
              marginBottom: "65px",
            }}
          >
            Amazon Event Chart:{" "}
            {chartData?.x_label &&
              `${getFromAndToDate(chartData, noOfDays).from} to ${
                getFromAndToDate(chartData, noOfDays).to
              }`}
          </Box>
          {!isLoading ? <BarCharts data={chartData} /> : <Skeleton />}
        </Box>
        <Box flex={1} p={1}>
          <TableData
            firstTabData={amazonEventData}
            firstTablLabel="Amazon Event Data"
            secondTabData={secondTabData}
            secondTablLabel="Details"
            name="Amazon Event Data"
            noOfDays={noOfDays}
            isLoading={isLoading}
          />
        </Box>
        <Box flex={1} p={1}>
          <TableData
            firstTabData={amazonBooksData?.sort((a, b) => b.count - a.count)}
            firstTablLabel="Details"
            secondTabData={secondTabData}
            secondTablLabel="Clicked Books Data"
            name="Clicked Books Data"
            noOfDays={noOfDays}
            isLoading={isLoading}
          />
        </Box>
      </Box>
    </div>
  );
};

export default AmazonEventChart;

export const colors = {
  green: "rgb(134, 195, 64)",
  yellow: "rgb(253, 223, 63)",
  orange: "rgb(246, 137, 33)",
  skyBlue: "rgb(26, 170, 225)",
  lightGray: "#ebebed",
  error: "rgb(211, 47, 47)"
};

export const isProdction =
  process.env.REACT_APP_ENV === "production" &&
  window.location.hostname === "callupcalendar.com";

export const baseballAmericaGoThrough =
  "https://www.baseballamerica.com/sign-up/?_ptid=%7Bkpdx%7DAAAA6GOTT6DwwgoKSXd2S0FqR1FwdRIQbHNrYXg0em85eGg2aTVzNRoMRVhTUVhaMVNXSTk0IiUxODA4aHNvMGM4LTAwMDAzM2QyOW1vcGM0ODFpcWpqbmk4ZTcwKhlzaG93VGVtcGxhdGU5NExMWVFYSTdJMkE3MAE6DE9UQ1ExS1VaT0ROMFJLdi0yMDI0LTAyLTEzLTA2LTQwLTU0LTY3MC1kSXpZemFQWVU2Mm5VcE5NLTM5OGNjNzMyNWZiNzNmYjE0NTRiNzRmNTJhOTYxOWI4WgwxNzMuAwDwBDIuOTdiA2R3Y2j50bKuBnABeAQ";

export const yardballGoThrough =
  "https://sandlotgoods.com/products/yardball-glove-tan-1";

export const fanGraphsGoThrough = "https://www.fangraphs.com/";

// Twitter keys
export const GLOBAL_URL =
  "https://prod-callupcalendar.netlify.app/.netlify/functions"; // test-callup
// export const GLOBAL_URL =
//   "http://192.168.1.160:9999/.netlify/functions"; // test-callup

export const validateEmail = (email) => {
  const emailPattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return emailPattern.test(email);
};

export const validatePassword = (password) => {
  // Password validation criteria can be adjusted as needed
  return password.length >= 8;
};
export const validateMobile = (mobile) => {
  // Check if the input contains any non-digit characters except the leading plus sign
  if (!/^\+?\D/.test(mobile)) {
      console.log(':contains non-numeric characters');
      return false; // Return false if input contains non-numeric characters (excluding the leading plus sign)
  }

  // Check if the input matches the mobile number pattern
  const mobilePattern = /^\+\d{1,15}$/; // Country code with 1-15 digits
  return mobilePattern.test(mobile);
};


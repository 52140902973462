import React from "react";
import { Typography, Button } from "@mui/material";
import { Link } from "react-router-dom";

const PageNotFound = ({ title }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        color: "white",
      }}
    >
      {/* // title is showing in case of error boundary */}
      <Typography variant="h2" style={{ marginBottom: "16px" }}>
        {title ? title : "Oops! Page Not Found"} 
      </Typography>
      <Typography
        variant="body1"
        style={{ marginBottom: "16px", textAlign: "center" }}
      >
        {!title && 'The page you are looking for is not available.'}
      </Typography>
      <Button variant="contained" color="primary" component={Link} to="/">
        Go to Home
      </Button>
    </div>
  );
};

export default PageNotFound;

import React, { Suspense } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./App.css";
import ReactGA from "react-ga";
import ScrollTracker from "./ScrollTracker";
import { v4 as uuidv4 } from "uuid";
import { handleAddEvent } from "./utils/helperFunction";

import { isProdction } from "./utils/Constant";
import AdminDashboard from "./screens/Admin/AdminDashboard";
import moment from "moment-timezone";
import { GlobalStateProvider } from "./context/DashboardDataContext";
import { AuthContextProvider } from "./context/AuthContext";
import LoadingScreenFullPage from "./components/LoadingScreenFullPage";
import ErrorBoundary from "./components/ErrorBoundary";
import PageNotFound from "./screens/PageNotFound";
moment.tz.setDefault("America/New_York");
const LazyDashboard = React.lazy(() => import("./screens/Dashboard/Index"));

function App() {
  if (isProdction) {
    console.log("Prod Env: GA Initialize successfully!");
    ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID);
  } else {
    console.log("Dev Env: GA not working on testing server!");
  }

  const uuid = JSON.parse(localStorage.getItem("uuid"));

  if (uuid === null) {
    const newUuid = uuidv4();
    localStorage.setItem("uuid", JSON.stringify(newUuid));
    const params = {
      eventType: "site_view",
      eventData: {
        Desc: "Event when user comes to the site",
      },
      userId: newUuid,
    };
    handleAddEvent(params);
  } else {
    const params = {
      eventType: "site_view",
      eventData: {
        Desc: "Event when user comes to the site",
      },
      userId: uuid,
    };
    handleAddEvent(params);
  }

  // useEffect(() => {
  //   // Set meta tag robots dynamically only on callupcalendar.com for indexing google crawler
  //   // const hostname = window.location.hostname;
  //   // let content = "noindex, nofollow"; // Default content
  //   // if (hostname === "callupcalendar.com") {
  //   //   content = "index, follow";
  //   // } else {
  //   //   content = "noindex, nofollow";
  //   // }
  //   // // Find the meta tag and set the correct content
  //   // const metaRobots = document.getElementById("metaRobots");
  //   // if (metaRobots) {
  //   //   metaRobots.setAttribute("content", content);
  //   // }
  // }, []);

  const router = createBrowserRouter([
    {
      path: "/",
      element: <LazyDashboard />,
    },
    {
      path: "/dashboard",
      element: <AdminDashboard />,
    },
    {
      path: "*",
      element: (
        <ErrorBoundary>
          <PageNotFound />
        </ErrorBoundary>
      ),
    },
  ]);

  return (
    <div className="App">
      <ScrollTracker />
      <Suspense fallback={<LoadingScreenFullPage />}>
        <AuthContextProvider>
          <GlobalStateProvider>
            <ErrorBoundary>
              <RouterProvider router={router} />
            </ErrorBoundary>
          </GlobalStateProvider>
        </AuthContextProvider>
      </Suspense>
    </div>
  );
}

export default App;

import React, { useEffect, useState } from "react";
import { BarChart } from "@mui/x-charts/BarChart";
import { LineChart } from "@mui/x-charts";

const BarCharts = ({ data }) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
  
    window.addEventListener('resize', handleResize);
  
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <div style={{display:'flex'}}>
      {data?.x_label && data?.y_label && (
        <LineChart
          xAxis={[{ scaleType: "band", data: data?.x_label }]}
          series={[{ data: data?.y_label }]}
          width={windowWidth >= 1560 ? 500 :  windowWidth >= 690 ? 600 : windowWidth >= 440 ? 390 : windowWidth >= 410 ? 370   :320}
          height={windowWidth >= 1560 ? 300 : 300}
          // style={{
          //   width: windowWidth >= 1000 ? '500px' : '100%',
          //   height: windowWidth >= 1000 ? '300px' : 'auto'
          // }}
        />
      )}
    </div>
  );
};

export default BarCharts;

import React, { useContext, useEffect, useState } from "react";
import { Box } from "@mui/material";
import BarCharts from "../../components/BarCharts";
import { apiCall } from "../../utils/httpClient";
import { GLOBAL_URL } from "../../utils/Constant";
import Skeleton from "../../components/Skeleton";
import TableData from "../../components/TableData";
import { getFromAndToDate } from "../../utils/helperFunction";
import GlobalStateContext from "../../context/DashboardDataContext";

const BuyNowTicketChart = ({ noOfDays, setSummaryData, summaryData }) => {
  const { buyNowSummary, setBuyNowSummary } = useContext(GlobalStateContext);

  const [chartData, setChartData] = useState({});
  const [buyNowEventData, setBuyNowEventData] = useState([]);
  const [teamData, setTeamData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [secondTabData, setSecondTabData] = useState([]);

  useEffect(() => {
    getBuyNowEventData();
  }, [noOfDays]);

  useEffect(() => {
    if (buyNowEventData.length > 0) {
      const x_label = buyNowEventData
        ?.map((obj) => obj?.dateRange)
        ?.filter((obj) => obj !== null)
        ?.reverse();

      const y_label = buyNowEventData
        ?.filter((obj) => obj?.dateRange !== null)
        ?.map((obj) => obj?.count)
        ?.reverse();
      setChartData({ ...chartData, x_label: x_label, y_label: y_label });
    }
  }, [buyNowEventData]);

  const getBuyNowEventData = async () => {
    setIsLoading(true);
    const response = await apiCall(
      "POST",
      `${GLOBAL_URL}/api/dashboard/getBuyNowEventData`,
      {
        daysToAdd: noOfDays,
      }
    );
    if (response?.status === 200) {
      setBuyNowEventData(response?.data?.buyNowData);
      const newData = response?.data?.teamVsData.map(obj => {
        const newObj = { ...obj }; // Create a copy of the object
        delete newObj['details']; // Delete details array because creating blank column in download csv not required yet 
        delete newObj['browseLocation']; // Delete browseLocation array because creating blank column in download csv not required yet 
        return newObj;
      });
      setTeamData(newData);
      setBuyNowSummary(response?.data?.buyNowData)
      const createTable = response?.data?.detailData
        ?.map((data) => {
          return {
            EventName: "Buy Now Ticket",
            Data_time: data.eventDate,
            TeamVs:data.teamVs,
            Country: data?.browseLocation?.country
              ? data?.browseLocation?.country
              : "",
            Region: data?.browseLocation?.region
              ? data?.browseLocation?.region
              : "",
            City: data?.browseLocation?.city ? data?.browseLocation?.city : "",
          };
        })
        .sort((a, b) => {
          const dateA = new Date(a.Data_time);
          const dateB = new Date(b.Data_time);
          return dateB - dateA;
        });
      setSecondTabData(createTable);
      setIsLoading(false);
    } else {
      setBuyNowEventData([]);
      setIsLoading(false);
    }
  };

  return (
    <div>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems={"center"}
        flexWrap={"wrap"}
      >
        <Box flex={1} p={1}>
          <Box
            sx={{
              fontSize: "25px",
              fontWeight: "400",
              textDecoration: "underline",
              marginBottom: "65px",
            }}
          >
            Buy Now Event Chart:{" "}
            {chartData?.x_label &&
              `${getFromAndToDate(chartData, noOfDays).from} to ${
                getFromAndToDate(chartData, noOfDays).to
              }`}
          </Box>
          {!isLoading ? <BarCharts data={chartData} /> : <Skeleton />}
        </Box>
        <Box flex={1} p={1}>
          <TableData
            firstTabData={buyNowEventData}
            firstTablLabel="Buy Now Event Data"
            secondTabData={secondTabData}
            secondTablLabel="Details"
            name="Buy Now Event Data"
            noOfDays={noOfDays}
            isLoading={isLoading}
          />
        </Box>
        <Box flex={1} p={1}>
          <TableData
            firstTabData={teamData?.sort((a, b) => b.count - a.count)}
            firstTablLabel="Buy Now Event Data"
            secondTabData={secondTabData}
            secondTablLabel="Details"
            name="Team Data"
            noOfDays={noOfDays}
            isLoading={isLoading}
          />
        </Box>
      </Box>
    </div>
  );
};

export default BuyNowTicketChart;

import React, { useContext, useEffect, useState } from "react";
import { MdOutlineFileDownload } from "react-icons/md";
import { utils, write, writeFile, writeXLSX } from "xlsx";

import { saveAs } from "file-saver";
import GlobalStateContext from "../context/DashboardDataContext";

const DownloadButton = ({
  firstTabData,
  firstTablLabel,
  buttonFor,
  secondTabData,
  secondTabLabel,
  noOfDays
}) => {
  const { amazonSummary, siteViewSummary, buyNowSummary } =
    useContext(GlobalStateContext);
  const [summaryData, setSummaryData] = useState([]);
  

  useEffect(() => {
    if (
      amazonSummary.length > 0 &&
      siteViewSummary.length > 0 &&
      buyNowSummary.length > 0
    ) {
      console.log('jhiiii');
      const totalPageViewCount = siteViewSummary.reduce(
        (acc, count) => acc + count.count,
        0
      );
      const totalbuyNowCount = buyNowSummary.reduce(
        (acc, count) => acc + count.count,
        0
      );
      const totalAmazonCount = amazonSummary.reduce(
        (acc, count) => acc + count.count,
        0
      );
      const mergedSummary = amazonSummary.map((amazonEntry) => {
        const siteViewEntry = siteViewSummary.find(
          (siteView) => siteView.dateRange === amazonEntry.dateRange
        );
        const buyNowEntry = buyNowSummary.find(
          (buyNow) => buyNow.dateRange === amazonEntry.dateRange
        );
        const buyNowCount = buyNowEntry ? buyNowEntry.count : 0;
        const pageViewCount = siteViewEntry ? siteViewEntry.count : 0;
        const amazonCount = amazonEntry ? amazonEntry.count : 0;

        return {
          dateRange: amazonEntry.dateRange,
          pageViews: pageViewCount,
          buyNowEvents: buyNowCount,
          buyNowRatio:
            (buyNowCount / pageViewCount) * 100
              ? `${parseFloat((buyNowCount / pageViewCount) * 100).toFixed(2)}%`
              : "0%",
          amazonEvents: amazonCount,
          amazonRatio:
            (buyNowCount / pageViewCount) * 100
              ? `${parseFloat((amazonCount / pageViewCount) * 100).toFixed(2)}%`
              : "0%",
        };
      });

      console.log(mergedSummary);
      const totalCountBottomRow = [
        {
          dateRange: "",
          pageViews: "",
          buyNowEvents: "",
          buyNowRatio: "",
          amazonEvents: "",
          amazonRatio: "",
        },
        {
          dateRange: "Total for " + noOfDays*10 +" days",
          pageViews: totalPageViewCount,
          buyNowEvents: totalbuyNowCount,
          buyNowRatio: (totalbuyNowCount / totalPageViewCount) * 100
          ? `${parseFloat((totalbuyNowCount / totalPageViewCount) * 100).toFixed(2)}%`
          : "0%",
          amazonEvents: totalAmazonCount,
          amazonRatio: (totalbuyNowCount / totalPageViewCount) * 100
          ? `${parseFloat((totalbuyNowCount / totalPageViewCount) * 100).toFixed(2)}%`
          : "0%",
        },
      ];
      console.log("mergedSummary", [...mergedSummary, ...totalCountBottomRow]);
      setSummaryData([...mergedSummary, ...totalCountBottomRow]);
    }
  }, [amazonSummary, siteViewSummary, buyNowSummary]);

  const downloadExcel = () => {
    const jsonToSheet = (json) => {
      const sheet = utils.json_to_sheet(json);
      return sheet;
    };

    // Create a new workbook
    const workbook = utils.book_new();

    // Convert data for the first tab
    const firstTabSheet = jsonToSheet(summaryData);
    utils.book_append_sheet(workbook, firstTabSheet, 'Summary');

    // Convert data for the second tab
    if (secondTabData && secondTabLabel) {
      const secondTabSheet = jsonToSheet(secondTabData);
      utils.book_append_sheet(workbook, secondTabSheet, secondTabLabel);
    }

    // Generate a binary representation of the Excel file
    // const excelBinary = writeFile(workbook, { type: "binary" });
    const excelBinary = write(workbook, { bookType: "xlsx", type: "binary" });

    // Convert binary data to a Blob
    const blob = new Blob([s2ab(excelBinary)], {
      type: "application/octet-stream",
    });

    // Trigger the download
    saveAs(blob, `${buttonFor}.xlsx`);
  };

  const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i < s.length; i++) {
      view[i] = s.charCodeAt(i) & 0xff;
    }
    return buf;
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        padding: "1px 6px",
        border: "1px solid black",
        borderRadius: "0 5px",
        cursor: "pointer",
      }}
      onClick={downloadExcel}
    >
      <MdOutlineFileDownload fontSize={20} />
      <span style={{ fontSize: "10px" }}>Excel</span>
    </div>
  );
};

export default DownloadButton;

import * as React from "react";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";

export default function BasicSelect({ value, setValue }) {
  const handleChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <Box>
      <FormControl>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          Days Interval
          <select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={value}
            label="Days"
            onChange={handleChange}
            size="small"
            style={{
              width: "150px",
              padding: "15px",
              fontSize: "20px",
              borderRadius: "5px",
              marginLeft: "0",
              marginTop: "3px",
            }}
          >
            <option value={1}>1-Day</option>
            <option value={2}>2-Days</option>
            <option value={3}>3-Days</option>
            <option value={5}>5-Days</option>
            <option value={7}>7-Days</option>
            <option value={15}>15-Days</option>
            <option value={30}>30-Days</option>
          </select>
        </Box>
      </FormControl>
    </Box>
  );
}

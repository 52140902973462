import React, { Component } from "react";
import PageNotFound from "../screens/PageNotFound";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError(error) {
    // Update state to indicate an error has occurred
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    // Log the error to an error reporting service
    console.error("Error caught by error boundary:", error, info);
  }

  render() {
    if (this.state.hasError) {
      // Render fallback UI
      return (
        <div>
          <PageNotFound title='Something went wrong!'/>
        </div>
      );
    }
    // Render children normally
    return this.props.children;
  }
}

export default ErrorBoundary;
